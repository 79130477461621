import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DEFAULT_WHITELABEL, findWhiteLabel} from '@dollar-flight-club/shared_utilities/lib/utils/whitelabel';
import {Api} from '../../services/api.service';
import {NgbCarouselConfig} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {NEWSLETTERS} from '../../models/newsletters';
import {MembershipName} from '@dollar-flight-club/shared_utilities/lib/models/Membership';

declare var gtag: Function;
declare var r: any;

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.css']
})
export class OnboardingComponent implements OnInit {
  @ViewChild('carousel') carousel: any;
  onboarding = false;
  slides: any[] = [];
  activeSlide: any = {};
  whitelabel_config: any;
  phoneForm: FormGroup;
  newsLetterForm: FormGroup;
  submitSuccess = false;
  submitError = false;
  newsletters = NEWSLETTERS;

  constructor(private route: ActivatedRoute, private router: Router, private api: Api, config: NgbCarouselConfig, formBuilder: FormBuilder, public ngxModalService: NgxSmartModalService) {
    this.whitelabel_config = findWhiteLabel(DEFAULT_WHITELABEL);
    localStorage.setItem('favicon',this.whitelabel_config.logos.favicon);
    localStorage.setItem('title',this.whitelabel_config.name);
    config.interval = 0;
    config.keyboard = false;
    this.phoneForm = formBuilder.group(
      { phone: ['', [Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]] }
    );

    const newsletterControls = {};
    this.newsletters.forEach(item => {
      newsletterControls[item.id] = formBuilder.control(false);
    });
    this.newsLetterForm = formBuilder.group(newsletterControls);
  }

  ngOnInit() {
    this.setUpSlides();
    this.api.user$.subscribe( async user =>{
      if(user){
        this.whitelabel_config = findWhiteLabel(user.partnerId);
      }
    });
    this.onboarding = JSON.parse(this.route.snapshot.queryParamMap.get('onboard'));
    if(this.onboarding){
      const plan = this.route.snapshot.queryParams.plan;
      this.trackEvent(plan);
      if(plan === MembershipName.premiumPlus){
        this.addViralSweepScript('https://app.viralsweep.com/promo/ca/195410-17233_1733421583');
        this.addViralSweepScript('https://app.viralsweep.com/promo/ca/195400-83831_1733422817');
      }
      return;
    }
    this.router.navigateByUrl('/dashboard');
  }
  private addViralSweepScript(tagUrl: string) {
    function vs_get_cookie(cname) {
      var name = cname + "=";
      var ca = document.cookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i].trim();
        if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
      }
      return "";
    }
    var vs_eidhash = vs_get_cookie('vseid');
    var vs_tag_url = `${tagUrl}?h=` + vs_eidhash;
    var head = document.getElementsByTagName('head')[0];
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = vs_tag_url;
    head.appendChild(script);

  }
  public nextSlide(){
    if(this.activeSlide.step === this.slides.length-1){
      this.router.navigateByUrl('/dashboard');
      return;
    }
    this.activeSlide = this.slides[this.activeSlide.step + 1];
    this.activeSlide.additionalClass = 'active';
  }

  public openModal(modal: string = 'videoModal'){
    const targetModal = this.ngxModalService.getModal(modal);
    if(targetModal){
      targetModal.open();
    }
  }

  async submitNewsletters(){
      this.nextSlide();
      const newsletters = this.newsLetterForm.value;
      await this.api.newsLetterSignup(newsletters);
  }

  async addPhoneNum(){
    try {
      this.phoneForm.disable();
      const success = await this.api.updateAccountInfo({phone: this.phoneForm.value.phone});
      success ? this.submitSuccess = true : this.submitError = true;
      this.phoneForm.enable();
      this.nextSlide();
    } catch (error) {
      this.phoneForm.controls.phone.reset();
      this.phoneForm.enable();
      this.submitSuccess = false;
      this.submitError = true;
    }
  }

  private trackEvent(plan: string){
    gtag('event', 'signup', {
      'event_category': plan,
      'event_label': plan === 'basic' ? 'Free membership sign up' : 'Premium signup',
      'value': 69//price
    });

  }

  private setUpSlides(){
    this.slides = [
      {
        step: 0,
        title: `Welcome to ${this.whitelabel_config.name}!`,
        subtitle: `Before we get started, did you want to subscribe to some of our travel newsletters?`,
        additionalClass: 'active',
      },
      {
        step: 1,
        title: `First, some homework!`,
        subtitle: `Take 1 minute to add us as an email contact & add us to your primary inbox.`,
        additionalClass: ''
      },
      {
        step: 2,
        title: `Want a text alert when we find a flight to your dream destination?`,
        subtitle: `Add your phone number and we'll send you a quick text alert so you can snag that dream deal before it's gone. Feature coming soon.`,
        additionalClass: ''
      }
    ];

    this.activeSlide = this.slides[0];
  }

}
