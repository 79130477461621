import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {Router} from '@angular/router';
import {Membership, Memberships, MembershipSpec} from '@dollar-flight-club/shared_utilities/lib/models/Membership';
import {Api, MessageType} from '../../../services/api.service';
import {MixpanelService} from '../../../services/mixpanel.service';
import * as _ from 'lodash';
import {environment} from '../../../../environments/environment';
import {catchError, tap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';

@Component({
  selector: 'app-cancel-modal',
  templateUrl: './cancel-modal.component.html',
  styleUrls: ['./cancel-modal.component.css', '../../../../assets/css/portal-responsive.css']
})
export class CancelModalComponent implements OnInit {
  //callback passed from parent to switch plans.
  @Output() public onComplete: EventEmitter<any> = new EventEmitter();
  @Input() couponId: number;
  onTrial: boolean;
  hideDiscount: boolean;
  plans: MembershipSpec[] = [];
  benefitsShort: any[] = [];
  selectedOption: any;
  couponSelected: any;
  membership: any;
  downgraded = false;
  pauseSuccess = false;
  error: boolean = false;
  subscription: any;
  stepTwo = false;
  extendTrialPeriod= false;
  loading= false;



  constructor(private router: Router, public ngxModalService: NgxSmartModalService, public api: Api, public mixpanel:MixpanelService) {
    this.benefitsShort = this.getShortListBenefits();
  }

  ngOnInit() {
    this.couponSelected = { key: this.couponId, basePlan: 'none' };

    const mems = _.reverse(Membership.getBaseMemberships());
    this.plans = _.map(mems, mem => {
      const memTar = _.find(this.benefitsShort, ['key', mem.key]);
      mem.benefits = memTar ? memTar.benefits : mem.benefits;
      return mem;
    });

    this.api.user$.subscribe(async u => {
      this.subscription = await this.api.membershipInfo();
      if(this.subscription.trialActive || this.subscription.status !== 'active'){
        this.hideDiscount = true;
        this.onTrial = true;
      }
      const id = _.get(u, 'membership', Memberships.free);
      this.membership = Membership.getMembershipSpec(id);
      const basePlan = _.find(this.plans, ['basePlan', this.membership.basePlan]);
      this.setSelectedOption(basePlan);
    });
  }

  public close(){
    this.ngxModalService.close('cancelModal');
  }

  public open(){
    this.ngxModalService.open('cancelModal');
  }

  setSelectedOption(selectedOption: any){
    this.selectedOption = selectedOption;
  }


  getShortListBenefits(): any[] {
    return [{
        key: Memberships.free,
        benefits: [
          '25% of our flight deals',
          'No domestic flight deals',
          'Ads'
        ],
      },
      {
        key: Memberships.premium,
        benefits: [
          '4X more of our best deals',
          'Weekend domestic flight deals',
          'Premium partner discounts'
        ]
      },
      //premium plus already has the right benefits
    ];
  }

  public openModal(updateBilling: boolean = false, modal: string = 'checkoutModal'){
    const targetModal = this.ngxModalService.getModal(modal);
    if(targetModal){
      targetModal.open();
    }
  }

  modalClose(modal: string = 'pauseModal'){
    const targetModal = this.ngxModalService.getModal(modal);
    if(targetModal){
      targetModal.close();
      this.loading = false;
      window.location.href='/profile/account'
    }
  }

  takeSurvey(){
    window.open('https://form.typeform.com/to/kbpfzc8j', '_blank', 'noopener');
  }

  getMembershipLabel(){
    return _.replace(this.membership.label, ' Trial', '');
  }

  pauseAccount() {
    this.loading = true;
    this.api.pause().pipe(
      tap(() => {
        this.loading = false;
        this.pauseSuccess = true;
        this.openModal(false, 'pauseModal');
      }),
      catchError(error => {
        this.loading = false;
        this.pauseSuccess = false;
        this.openModal(false, 'pauseModal');
        console.error(error);
        return of(null);
      })
    ).subscribe();
  }

  extendTrial() {
    this.loading = true;
    this.api.extendTrial().pipe(
      tap(() => {
        this.loading = false;
        this.extendTrialPeriod = true;
        this.openModal(false, 'trialExtendedModal');
      }),
      catchError(error => {
        this.loading = false;
        this.extendTrialPeriod = false;
        this.openModal(false, 'trialExtendedModal');
        console.error(error);
        return of(null);
      })
    ).subscribe();
  }

  trackClick(msg: string){
    this.mixpanel.track(`Clicked ${msg} button`);
  }

  async continueOn(){
    this.stepTwo = true;
  }

  async cancelAccount() {
    this.downgraded = true;
    this.stepTwo = false;
    this.api.showPageLoader();
    const success = await this.api.unsubscribe();
    if(!success){
      this.error = true
    }
    this.api.hidePageLoader();
  }

  keepPlanWithCoupon() {
    this.close();
    const couponCode = this.onTrial ? environment.trialCoupon : environment.cancellationCoupon;
    this.api.applyCoupon(couponCode).pipe(
      tap(() => {
        this.openModal(false, 'discountModal');
      }),
      catchError((error) => {
        this.openModal(false, 'discountFailedModal');
        console.error(error);
        return of(null);
      })
    ).subscribe();
  }
}
